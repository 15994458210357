import { hooks, state } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useMemo, useState } from "react";

import { ProductActionOutcome } from "~/components/common/product-actions";
import { GiftCard } from "~/components/product/product-gift-addtobag";
import { useBreakpoint } from "~/contexts/breakpoint";
import { useCartNotification } from "~/contexts/cart-notification";
import { useConfiguration } from "~/contexts/configuration";
import { algoliaAddToBag } from "~/services/algolia-utils";
import Logger from "~/services/logger/logger";
import { ChangeQuantityDesktopProps, ChangeQuantityMobileProps } from "~/shared/product/quantity-selector-fields";
import { ListProductTrackingInfo } from "~/types/trackings.models";
import TrackingUtils from "~/utils/tracking-utils";

import { useGlobalNotification } from "./use-global-notification";
import { useLinesProductsHierarchicalData } from "./use-lines-container";

type couponErrorResponse = {
  Code: string;
  Message: string;
  RequestID: string;
  Type: "Discounts:InvalidCoupon" | "Discounts:CouponAlreadyAdded";
};

function _trackCartEcomAction(action: ProductActionOutcome, quantity: number, trackingData?: ListProductTrackingInfo) {
  if (trackingData) {
    if (trackingData.info) {
      trackingData.info.quantity = quantity;
    }

    const trackPayload = TrackingUtils.mapBasketAction("cart", action, trackingData.context ?? "", trackingData.info);
    trackPayload && TrackingUtils.track(trackPayload);
  }
}

export function useCart() {
  hooks.useServiceRequestPersistence(state.checkout.shoppingCartState);
  return hooks.useGetState(state.checkout.shoppingCartState.response);
}

export function useCartProductsHierarchicalData() {
  const cart = useCart();
  return useLinesProductsHierarchicalData(cart?.ShoppingCart.Lines || []);
}

// This hook is used to check the availability of products in the cart.
export function useCartAvailability() {
  const cart = useCart();
  const cartId = cart?.ShoppingCart.ID;
  const evaEndpointUrl = hooks.useGetState(state.core.evaEndpointUrlState);

  // Set the shopping cart state to stale
  const getShoppingCart = hooks.useCallService({ service: Core.GetShoppingCart });
  const getProductAvailability = hooks.useCallService({ service: Core.GetProductAvailability });

  let cartProducts = cart?.ShoppingCart.Lines.filter((line) => line.Product?.Properties) ?? [];
  let cartProductIds = cartProducts.map((p) => p.ProductID) ?? [];

  // Filter the product IDs that are either type 8 or digital gift cards
  let excludedProductIds = cartProducts
    .filter((p) => p.Product?.Properties.product_types.includes(8) || p.IsDigitalGiftCard)
    .map((p) => p.ProductID!);

  // Get the products availability
  const { data: cartAvailabilityData, refetch } = useQuery(
    ["getProductAvailability", cartId, ...cartProductIds],
    async () => {
      if (cartProductIds.length > 0) {
        return await getProductAvailability({
          Products: cartProducts?.map((line) => ({ ID: line.ProductID, QuantityRequested: line.QuantityOpen })),
          Options: {
            Pickup: {
              IncludePickupOrganizationUnits: true,
              IncludeOrganizationUnitsWithoutStock: true,
            },
            Delivery: {
              AvailabilityDate: true,
            },
          },
        });
      }
      return null;
    },
    {
      enabled: !!evaEndpointUrl && Boolean(cartProducts?.length),
    }
  );

  // Define a function to update cart availability
  const updateCartAvailability = async () => {
    // Call the service to get the shopping cart
    const dataShoppingCart = await getShoppingCart({
      AdditionalOrderDataOptions: {
        IncludeAvailablePaymentMethods: false,
        IncludeAvailableRefundPaymentMethods: false,
        IncludeAvailableShippingMethods: false,
        IncludeCheckoutOptions: false,
        IncludeCustomerCustomFields: false,
        IncludeGiftCardBusinessRules: false,
        IncludeGiftWrapping: false,
        IncludeOrganizationUnitData: false,
        IncludePaymentTransactionActions: false,
        IncludePickProductOptions: false,
        IncludePrefigureDiscounts: false,
        IncludeProductRequirements: false,
        IncludeValidateShipment: false,
        ProductProperties: [],
      },
      OrderID: cartId,
    });

    // Filter the cart products that have properties
    cartProducts = dataShoppingCart?.ShoppingCart.Lines.filter((line) => line.Product?.Properties) ?? [];
    await refetch();
  };

  // Return the cart availability data if it exists
  return cartAvailabilityData
    ? {
        ...cartAvailabilityData,
        AllAvailable: cartAvailabilityData?.Products.every(
          (p) => p.Delivery?.HasStock || excludedProductIds.includes(p.ProductID!)
        ),
        updateCartAvailability,
      }
    : undefined;
}

export function useCartWithPendingStatus() {
  const isLoadingCart = hooks.useIsServiceLoading({ serviceState: state.checkout.shoppingCartState });
  hooks.useServiceRequestPersistence(state.checkout.shoppingCartState);
  const cart = hooks.useGetState(state.checkout.shoppingCartState.response);
  return { cart, isLoadingCart };
}

export function useCartWithHooks() {
  const cart = useCart();
  const [couponError, setCouponError] = useState<couponErrorResponse>();

  const setPickProduct = hooks.useShoppingCartModify({ service: Core.SetPickProductDiscountOptionsForOrderLine });
  const addCouponCode = hooks.useShoppingCartAdd({
    service: Core.AddDiscountCouponToOrder,
    options: {
      onError: async (error) => {
        if (error instanceof HTTPError) {
          const jsonError = await (error as HTTPError).response.json();
          setCouponError(jsonError.Error);
        }
      },
    },
  });
  const cancelDiscountOrderLine = hooks.useShoppingCartModify({ service: Core.CancelDiscountOrderLine });

  return { cart, addCouponCode, setPickProduct, cancelDiscountOrderLine, couponError, setCouponError };
}

export function useCartProduct(productId: number, lineID?: number, trackingData?: ListProductTrackingInfo) {
  const cart = useCart();
  const { algoliaIndexId } = useConfiguration();
  const { notifyAdded, notifyRemoved, notifyModified, notifyFixed, resetNotifications } = useCartNotification();
  const { newErrorGlobalNotification, closeGlobalNotification } = useGlobalNotification();

  const cartErrorGlobalNotification = () => {
    newErrorGlobalNotification({
      slug: "cartGenericError",
      closeOnClickCta: true,
      ctaCaption: "generic.cancel",
    });
  };

  const line = useMemo(() => {
    if (lineID) {
      return cart?.ShoppingCart.Lines.find((line) => line.ID === lineID) ?? null;
    }
    return cart?.ShoppingCart.Lines.find((line) => line.ProductID === productId) ?? null;
  }, [cart?.ShoppingCart.Lines, lineID, productId]);

  const isCartPaid = !!cart?.ShoppingCart.IsPaid;

  const shoppingCartAdd = hooks.useShoppingCartAdd({ service: Core.AddProductToOrder });
  const addDigitalCard = hooks.useShoppingCartAdd({ service: Core.AddDigitalGiftCardToOrder });
  const shoppingCartModify = hooks.useShoppingCartModify({ service: Core.ModifyQuantityOrdered });
  const shoppingCartCancel = hooks.useShoppingCartModify({ service: Core.CancelOrder });
  const getProductRequiremens = hooks.useCallService({ service: Core.GetProductRequirementValuesForOrderLine });
  const updateProductRequirements = hooks.useShoppingCartAdd({
    service: Core.UpdateProductRequirementValuesForOrderLine,
  });
  const refreshCart = hooks.useSetServiceStale({ serviceState: state.checkout.shoppingCartState });

  const queryClient = useQueryClient();
  const addGiftCard = useMutation(
    async (giftParams: GiftCard) => {
      try {
        const addedAt = new Date();

        if (giftParams.isVirtual) {
          const additionalData = {
            FromEmailAddress: cart?.ShoppingCart.Customer?.EmailAddress || undefined,
            ToEmailAddress: giftParams.recipient,
            Theme: {
              ImageUrl: giftParams.imageUrl,
              Name: giftParams.cardName,
            },
          } as EVA.Core.GiftCardData;

          // if gift add custom fields
          if (giftParams.isGift) {
            additionalData.From = giftParams.senderName;
            additionalData.To = giftParams.recipientName;
            additionalData.Text = giftParams.message;
          }
          await addDigitalCard({
            orderType: 0,
            payload: {
              ProductID: productId,
              Amount: giftParams.amount || giftParams.customAmount,
              Data: additionalData,
            },
          }).then((data) => {
            if (!!data) {
              // console.info("[GIFTCARD] add to cart successfully: ", data);
              notifyAdded(addedAt);
            } else {
              throw new Error("addGiftCard > addDigitalCard errored");
            }
          });
        } else {
          const shoppingCartAddResponse = await shoppingCartAdd({
            orderType: 0,
            createNewOrderWhen: "paid",
            payload: {
              ProductID: productId,
              QuantityOrdered: 1,
              UnitPriceInTax: giftParams.amount || giftParams.customAmount,
            },
          });

          if (!shoppingCartAddResponse) {
            throw new Error("addGiftCard > shoppingCartAdd errored");
          }

          if (!!shoppingCartAddResponse) {
            // console.info("[GIFTCARD] add to cart successfully: ", data);
            if (giftParams.isGift) {
              const productRequirementsResponse = await getProductRequiremens({
                OrderLineID: shoppingCartAddResponse.OrderLineID,
              });

              if (!productRequirementsResponse) {
                throw new Error("addGiftCard > getProductRequiremens errored");
              }

              if (productRequirementsResponse?.Requirements) {
                // assuming SenderName and RecipientName will not change in position between org units, otherwise we need to find another way to handle this
                const requiremensIds = Object.keys(productRequirementsResponse.Requirements);
                await updateProductRequirements({
                  orderType: 0,
                  payload: {
                    OrderLineID: shoppingCartAddResponse.OrderLineID,
                    Values: {
                      [requiremensIds[0]]: giftParams.senderName,
                      [requiremensIds[1]]: giftParams.recipientName,
                    },
                  },
                }).then((data) => {
                  if (!data) throw new Error("addGiftCard > updateProductRequirements errored");
                });
                // recoil state inconsistent update
                // TEMP FIX with setTimeout
                setTimeout(() => {
                  refreshCart();
                }, 100);
              }
            }
            notifyAdded(addedAt);
          }
        }
      } catch (err) {
        Logger.instance.error("Usecart > addGiftCard errored", err);
        throw err;
      }
    },
    {
      onSuccess: () => {
        closeGlobalNotification();
        queryClient.invalidateQueries(["digitalGiftCardsDetails", cart?.ShoppingCart.ID]);
      },
      onError: () => {
        resetNotifications();
        cartErrorGlobalNotification();
      },
    }
  );

  const addToCart = useMutation({
    mutationFn: async () => {
      try {
        const addedAt = new Date();

        if (line?.QuantityOpen) {
          await shoppingCartModify({
            payload: { Lines: [{ OrderLineID: line.ID, NewQuantityOrdered: line.QuantityOpen + 1 }] },
          }).then((data) => {
            if (!data) throw new Error("addToCart > shoppingCartModify errored");
          });
        } else {
          await shoppingCartAdd({
            orderType: 0,
            createNewOrderWhen: "paid",
            payload: { ProductID: productId, QuantityOrdered: 1 },
          }).then((data) => {
            if (!data) throw new Error("addToCart > shoppingCartAdd errored");
          });

          _trackCartEcomAction("added", 1, trackingData);
        }

        notifyAdded(addedAt);
      } catch (err) {
        Logger.instance.error("Usecart > addToCart errored", err);
        throw err;
      }
    },
    onSuccess: () => {
      closeGlobalNotification();
    },
    onError: () => {
      resetNotifications();
      cartErrorGlobalNotification();
    },
  });

  //TODO: removeFromCart unused
  const removeFromCart = useMutation({
    mutationFn: async () => {
      const removedAt = new Date();

      if (!line) {
        return;
      }

      if (line.QuantityOpen === 1) {
        await shoppingCartCancel({
          payload: { OrderID: line.OrderID, OrderLines: [{ OrderLineID: line.ID }] },
        });
      } else {
        await shoppingCartModify({
          payload: { Lines: [{ OrderLineID: line.ID, NewQuantityOrdered: line.QuantityOpen - 1 }] },
        });
      }

      _trackCartEcomAction("removed", 1, trackingData);

      notifyRemoved(removedAt);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["digitalGiftCardsDetails", cart?.ShoppingCart.ID]);
    },
  });

  // TODO: maybe not the best solution to pass max quantity to the hook
  const changeQuantity = useMutation({
    mutationFn: async (
      data: ReturnType<typeof useBreakpoint> extends "desktop" ? ChangeQuantityDesktopProps : ChangeQuantityMobileProps
    ) => {
      try {
        const modifiedAt = new Date();

        if (!line) {
          return;
        }

        if (line.QuantityOpen === data.Quantity) {
          return;
        }

        const diffQuantity = data.Quantity - line.QuantityOpen;

        if (diffQuantity < 0) {
          // removal of items
          if (data.Quantity <= 0) {
            await shoppingCartCancel({
              payload: { OrderID: line.OrderID, OrderLines: [{ OrderLineID: line.ID }] },
            }).then((data) => {
              if (!data) throw new Error("changeQuantity > shoppingCartCancel errored");
            });
            notifyRemoved(modifiedAt);
          } else {
            await shoppingCartModify({
              payload: { Lines: [{ OrderLineID: line.ID, NewQuantityOrdered: data.Quantity }] },
            }).then((data) => {
              if (!data)
                throw new Error("changeQuantity (diffQuantity < 0 && data.Quantity > 0) > shoppingCartModify errored");
            });
            notifyModified(modifiedAt);
          }
          _trackCartEcomAction("removed", -diffQuantity, trackingData);
        } else if (diffQuantity > 0) {
          // adding of items
          if (line?.QuantityOpen) {
            if (data.Quantity === 1) {
              await shoppingCartAdd({
                orderType: 0,
                createNewOrderWhen: "paid",
                payload: { ProductID: productId, QuantityOrdered: 1 },
              }).then((data) => {
                if (!data)
                  throw new Error("changeQuantity (diffQuantity > 0 && data.Quantity === 1) > shoppingCartAdd errored");
              });
              notifyAdded(modifiedAt);
            } else if (data.Quantity > data.maxQuantity) {
              await shoppingCartModify({
                payload: { Lines: [{ OrderLineID: line.ID, NewQuantityOrdered: data.maxQuantity }] },
              }).then((data) => {
                if (!data)
                  throw new Error(
                    "changeQuantity > shoppingCartModify (diffQuantity > 0 && data.Quantity > data.maxQuantity) errored"
                  );
              });
              notifyFixed(modifiedAt);
            } else {
              await shoppingCartModify({
                payload: { Lines: [{ OrderLineID: line.ID, NewQuantityOrdered: data.Quantity }] },
              }).then((data) => {
                if (!data) throw new Error("changeQuantity > shoppingCartModify errored");
              });
              notifyModified(modifiedAt);
            }
            _trackCartEcomAction("added", diffQuantity, trackingData);
            algoliaAddToBag(algoliaIndexId, productId, trackingData?.info, diffQuantity);
          }
        }
      } catch (err) {
        Logger.instance.error("Usecart > changeQuantity errored", err);
        throw err;
      }
    },
    onSuccess: () => {
      closeGlobalNotification();
    },
    onError: () => {
      resetNotifications();
      cartErrorGlobalNotification();
    },
  });

  const removeAllFromCart = useMutation({
    mutationFn: async () => {
      if (!line) {
        return;
      }

      const diffQuantity = line?.QuantityOpen;
      await shoppingCartCancel({
        payload: { OrderID: line.OrderID, OrderLines: [{ OrderLineID: line.ID }] },
      });
      _trackCartEcomAction("removed", diffQuantity, trackingData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["digitalGiftCardsDetails", cart?.ShoppingCart.ID]);
    },
  });

  return {
    line,
    quantityInCart: line?.QuantityOpen ?? 0,
    addToCart,
    addGiftCard,
    changeQuantity,
    removeFromCart,
    removeAllFromCart,
    isCartPaid,
  };
}

export function useCartCheckout() {
  const cart = useCart();
  const cartAvailability = useCartAvailability();

  const getShoppingCart = hooks.useCallService({ service: Core.GetShoppingCart });
  const changeOrderLinesToDelivery = hooks.useShoppingCartModify({ service: Core.ChangeOrderLinesToDelivery });
  const changeOrderLinesToPickup = hooks.useShoppingCartModify({ service: Core.ChangeOrderLinesToPickup });
  const updateOrderShippingAddress = hooks.useShoppingCartModify({ service: Core.UpdateOrderShippingAddress });
  const updateOrderBillingAddress = hooks.useShoppingCartModify({ service: Core.UpdateOrderBillingAddress });
  const updateOrderAddresses = hooks.useShoppingCartModify({ service: Core.UpdateOrderAddresses });
  const prepareOrderForCheckout = hooks.useShoppingCartModify({ service: Core.PrepareOrderForCheckout });
  const placeOrder = hooks.useShoppingCartModify({ service: Core.PlaceOrder });
  const getGiftWrappingOptionsForOrder = hooks.useShoppingCartModify({ service: Core.GetGiftWrappingOptionsForOrder });
  const setGiftWrappingOptionsOnOrder = hooks.useShoppingCartModify({ service: Core.SetGiftWrappingOptionsOnOrder });
  const updateOrderCustomFields = hooks.useShoppingCartModify({ service: Core.UpdateOrderCustomFields });

  return {
    cart,
    cartAvailability,
    getShoppingCart,
    changeOrderLinesToDelivery,
    changeOrderLinesToPickup,
    updateOrderShippingAddress,
    updateOrderBillingAddress,
    updateOrderAddresses,
    prepareOrderForCheckout,
    placeOrder,
    setGiftWrappingOptionsOnOrder,
    getGiftWrappingOptionsForOrder,
    updateOrderCustomFields,
  };
}
